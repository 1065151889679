import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function InsuranceSkladUsa() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    status: '',
    name: '',
    price: '',
    country: null,
    shippingCompany: null,
    insuranceType: '',
  }

  this.validation = {
    id: false,
    status: false,
    name: false,
    price: false,
    country: false,
    shippingCompany: false,
    insuranceType: false,
  }

  this.validationTranslate = {
    id: '',
    status: '',
    name: '',
    price: '',
    country: '',
    shippingCompany: '',
    insuranceType: '',
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getName = () => {
    return this.data.name
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getShippingCompany = () => {
    return this.data.shippingCompany
  }
  this.getInsuranceType = () => {
    return this.data.insuranceType
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
  this.setShippingCompany = (val) => {
    this.data.shippingCompany = val
  }
  this.setInsuranceType = (val) => {
    this.data.insuranceType = val
  }
}

/**
 * Global Setters
 */

InsuranceSkladUsa.prototype.setItem = function (item) {
  this.setId(item?.id)
  // this.setStatus(item?.status)
  this.setName(item?.name)
  this.setPrice(item?.price)
  this.setCountry(item?.from_country_group)
  this.setShippingCompany(item?.shipping_company)
  this.setInsuranceType(item?.insuranceType)
}

/**
 * Prepare Data
 */

InsuranceSkladUsa.prototype.prepareSave = function () {
  return {
    'shipping_company_id': this.getShippingCompany()?.id,
    'name': this.getName(),
    'price': this.getPrice(),
    'insurance_type': this.getInsuranceType(),
    'from_country_group_id': this.getCountry()?.id,
  }
}

/**
 * Validations
 */

InsuranceSkladUsa.prototype.firstValidationUser = function () {

  let validationItems = {
    name: this.getName(),
    price: this.getPrice(),
    country: this.getCountry()?.id,
    shippingCompany: this.getShippingCompany()?.id,
    // insuranceType: this.getInsuranceType(),
  }

  let validationOptions = {
    name: {type: ['empty']},
    price: {type: ['empty']},
    country: {type: ['empty']},
    shippingCompany: {type: ['empty']},
    // insuranceType: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
