
export const InsuranceSkladUsaMixin = {

  methods: {
    save(edit = false) {

      if(!this.ISU.firstValidationUser()) return

      let data = this.ISU.prepareSave(edit)

      let saveType = 'createInsuranceSkladUsa'

      if (edit) {
        data = {
          id: this.ISU.getId(),
          data: data
        }
        saveType = 'updateInsuranceSkladUsa'
      }

      console.log(data);
      console.log(saveType);

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
        console.log(data);

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    }
  }

}
