<template>
  <div class="fragment">

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'insuranceSkladUsa_name',
          ])"></div>
        <DefaultInput
            :label="$t('insuranceSkladUsa_name.localization_value.value')"
            :maxLength="3"
            v-bind:class="{'ui-no-valid': ISU.validation.name}"
            :errorTxt="$t(`${ISU.validationTranslate.name}.localization_value.value`)"
            :error="ISU.validation.name"
            v-model="ISU.data.name"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'insuranceSkladUsa_price',
          ])"></div>
        <DefaultInput
                :label="$t('insuranceSkladUsa_price.localization_value.value')"
                v-bind:class="{'ui-no-valid': ISU.validation.price}"
                :errorTxt="$t(`${ISU.validationTranslate.price}.localization_value.value`)"
                :error="ISU.validation.price"
                v-model="ISU.data.price"
        />
      </div>

      <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate([-->
          <!--'iossNumbers_number',-->
          <!--])"></div>-->
        <!--<DefaultInput-->
                <!--:label="$t('insuranceSkladUsa_insuranceType.localization_value.value')"-->
                <!--v-bind:class="{'ui-no-valid': ISU.validation.insuranceType}"-->
                <!--:errorTxt="$t(`${ISU.validationTranslate.insuranceType}.localization_value.value`)"-->
                <!--:error="ISU.validation.insuranceType"-->
                <!--v-model="ISU.data.insuranceType"-->
        <!--/>-->
      <!--</div>-->
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="customs-info__search-input">
          <DefaultSelect
              :options="countyGroups"
              :label="$t('insuranceSkladUsa_countryGroup.localization_value.value')"
              v-bind:class="{'ui-no-valid': ISU.validation.country}"
              :errorTxt="$t(`${ISU.validationTranslate.country}.localization_value.value`)"
              :error="ISU.validation.country"
              :optionsLabel="'name'"
              @change="changeCountry"
              :selected="ISU.data.country"
          />
        </div>
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="customs-info__search-input">
          <DefaultSelect
              :options="shippingCompanies"
              :label="$t('insuranceSkladUsa_shippingCompany.localization_value.value')"
              v-bind:class="{'ui-no-valid': ISU.validation.shippingCompany}"
              :errorTxt="$t(`${ISU.validationTranslate.shippingCompany}.localization_value.value`)"
              :error="ISU.validation.shippingCompany"
              :optionsLabel="'name'"
              @change="changeShippingCompany"
              :selected="ISU.data.shippingCompany"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../mixins/filterFuncMixin/filterFunc";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {InsuranceSkladUsaMixin} from "../../../../../mixins/insuranceSkladUsaMixins/insuranceSkladUsaMixin";

export default {
  name: "FieldsBlock",

  components: {
    DefaultSelect,
    DefaultInput,
  },

  mixins: [filterFunc, InsuranceSkladUsaMixin],

  props: {
    ISU: Object,
    countyGroups: {
      type: Array,
      default: null
    },
    shippingCompanies: {
      type: Array,
      default: null
    },
  },

  mounted() {
  },

  data() {
    return {
      country: '',
    }
  },

  methods: {

    changeCountry(val) {
      this.ISU.setCountry(val)
    },

    changeShippingCompany(val) {
      this.ISU.setShippingCompany(val)
    },

  },
}
</script>
