<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_InsuranceSkladUsa',])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'customs-info'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('menu_InsuranceSkladUsa.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['insuranceSkladUsa_BackToList',])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa"
                             class="order-create__head-back">
                  <LinkBack
                          :value="$t('insuranceSkladUsa_BackToList.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['menu_createInsuranceSkladUsa',])"></div>
              {{$t('menu_createInsuranceSkladUsa.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <FieldsBlock
                    v-if="_.has(createData, 'from_country_groups')"
                    :ISU="ISU"
                    :countyGroups="createData.from_country_groups.data"
                    :shippingCompanies="createData.shipping_companies"
            />
          </div>
        </template>

        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save', false, 'open')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import FieldsBlock from "../../chunks/FieldsBlock";

  export default {
    name: "InsuranceSkladUsaCreationSection",

    components: {
      FieldsBlock,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    props: {
      ISU: Object,
      createData: Object,
    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

<style lang="scss">

</style>
