<template>
  <InsuranceSkladUsaCreationSection
          :ISU="ISU"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import InsuranceSkladUsaCreationSection from "./InsuranceSkladUsaCreationSection/InsuranceSkladUsaCreationSection";
  import {InsuranceSkladUsa} from "../../models/InsuranceSkladUsa";
  import {InsuranceSkladUsaMixin} from "../../../../../mixins/insuranceSkladUsaMixins/insuranceSkladUsaMixin";

  export default {
    name: "InsuranceSkladUsaCreation",

    components:{
      InsuranceSkladUsaCreationSection,
    },

    mixins: [InsuranceSkladUsaMixin],

    data() {
      return {
        ISU: new InsuranceSkladUsa(),

        createData: null,
      }
    },

    mounted() {

      this.$store.dispatch('getInsuranceSkladUsaCreate').then(response => {
        this.createData = this.getRespData(response)
      })

    },

    methods: {
    }

  }
</script>

<style scoped>

</style>
